import {ILoginResponseDto, KnoxAuthApiService, SecurityAuthService} from '@aex/security/shared';
import { CustomerService } from '@aex/shared/apis';
import { ENVIRONMENT_CONFIG_TOKEN, ICustomer, IEnvironment, KEY_PROVIDER_TOKEN } from '@aex/shared/common-lib';
import { ConfigService } from '@aex/shared/root-services';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// import {SecurityLoginHandlerService} from "@aex/security/authentication";

@Injectable({
	'providedIn': 'root',
})
// export class ClientInterfaceAuthService extends AuthService {
export class ClientInterfaceAuthService extends SecurityAuthService {

	private get clientInterfaceConfigService(): ConfigService {
		return this.configService as ConfigService;
	}

	constructor(
		http: HttpClient,
		router: Router,
		configService: ConfigService,
		private readonly customerService: CustomerService,
		@Inject(ENVIRONMENT_CONFIG_TOKEN) environment: IEnvironment,
		knoxAuthApiService: KnoxAuthApiService
		// private readonly securityLoginHandlerService: SecurityLoginHandlerService,
	) {
		super(http, router, configService, environment, knoxAuthApiService);
	}

	protected override getToken(key: string): string {
		return this.clientInterfaceConfigService.retrieve(key);
	}

	// public override setAuthToken(value: string): void {
	// 	super.setAuthToken(value);
	// 	this.clientInterfaceConfigService.store(KEY_AUTH_TOKEN, value);
	// }

	public override bustCaches(): Observable<void> {
		this.customerService.currentUser = null;
		return super.bustCaches();
	}

	protected override setProviderToken(value: string) {
		super.setProviderToken(value);
		this.clientInterfaceConfigService.store(KEY_PROVIDER_TOKEN, value);
	}

	public override processLoginResponse(username: string, loginResponseDto: ILoginResponseDto): void {
		super.processLoginResponse(username, loginResponseDto);
		// this.securityLoginHandlerService.determineLoginResponse(loginResponseDto, username, '');
	}

	protected getCachedToken(key:string): string {
		return this.clientInterfaceConfigService.retrieve(key);
	}

	public doGetLoginCustomer(username: string): Observable<ICustomer> {
		return this.customerService.getCustomerPremises(username);
	}
}
