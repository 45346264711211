import {IStripeSetupIntent, IStripeSetupIntentResponse} from '@aex/shared/common-lib';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StripePaymentIntentApi } from '../api';

@Injectable({
	providedIn: 'root',
})

export class StripePaymentIntentService {

	constructor(private readonly http: HttpClient) { }

	public createSetupIntent(customerId: string, collectorId: string, mediumType: string): Observable<IStripeSetupIntentResponse> {
		const stripeSetupIntent: IStripeSetupIntent = {
			customer_id: customerId,
			collector_id: collectorId,
			medium_type: mediumType,
		};
		return this.http.post<IStripeSetupIntentResponse>(StripePaymentIntentApi.createSetupIntent, stripeSetupIntent);
	}
}
