import { GeocoderAddressComponent, GeocoderResult } from '@agm/core/services/google-maps-types';
import PlaceResult = google.maps.places.PlaceResult;

export interface IPremises {
	id?: string;
	latitude: string | number;
	longitude: string | number;
	street_number: string;
	street_name: string;
	suburb: string;
	city: string;
	country_code: string;
	postal_code: string;
	province: string;
	intersection: string;
	type_id?: string;
	customer_id: string;
	name: string;
	full_address: string;
	mdu_number?: string | null;
	mdu_number_int?: string | null;
	mdu_unit?: string;
	mdu_name?: string;
	external_reference: string;
	asset_reference: string;
	guid?: string;
	formatted_address?: string;
	updated_at?: string;
	created_at?: string;
}

export interface IPremisesUpdate {
	premise: Partial<IPremises>;
	update_for_empty_customer?: boolean;
}

export interface IPredefinedPremise {
	id: number;
	guid: string;
	full_text: string;
	created_at?: string;
	updated_at?: string;
	latitude?: number;
	longitude?: number;
	deleted_at?: string;
	premise_id?: string;
	premise_type?: string | null;
	area_id?: string;
}

export interface IPredefinedPremises {
  id: number;                               // int
  guid?: string;                            // uniqueidentifier (UUID string) (nullable)
  full_text: string;                        // varchar(255)
  latitude?: number;                        // float (nullable)
  longitude?: number;                       // float (nullable)
  premise_type: number;                     // int
  area_id: string;                          // uniqueidentifier (UUID string)
  predefined_premise_status_id?: number;    // int (nullable)
  created_at?: Date | null;                 // datetime (nullable)
  updated_at?: Date | null;                 // datetime (nullable)
  deleted_at?: Date | null;                 // datetime (nullable)  
}

export interface IFullPredefinedPremises extends IPredefinedPremises {
  area_name?: string;                             // Area name
  predefined_premises_status_name?: string;       // PDP status name
  predefined_premises_status_value?: string;      // PDP status description or Area-status
}

export interface IClientPremise {
	id: number;
	area_name: string;
	asset_reference: string;
	city?: string;
	country_code?: string;
	latitude?: number;
	longitude?: number;
	mdu_block?: string;
	mdu_floor?: string;
	mdu_name?: string;
	mdu_unit?: string;
	postal_code?: string;
	province?: string;
	street_name?: string;
	street_number?: string;
	suburb?: string;
	intersection?: string;
	deleted_at?: string;
	predefined_premises_id?: string;
	nid?: boolean;
	created_at?: string;
	updated_at?: string;
}

export interface IPredefinedPremiseResponse {
	items: IPredefinedPremise[];
}

export interface IPredefinedPremiseSearch {
	area_id?: string;
	full_text?: string;
	latitude?: string;
	longitude?: string;
}

export function premiseFromPlace(place: GeocoderResult | PlaceResult, getLatLong: boolean = false): Partial<IPremises> {
	let premises: Partial<IPremises> = {};

	if (getLatLong) {
		premises.latitude = place.geometry.location.lat();
		premises.longitude = place.geometry.location.lng();
	}

	for (const component of place.address_components) premises = extrapolate(component, premises);

	return premises;
}

function extrapolate(component: GeocoderAddressComponent | google.maps.GeocoderAddressComponent, premises: Partial<IPremises>) {
	if (component.types.includes('street_number')) premises.street_number = component.short_name;
	else if (component.types.includes('plus_code')) {
		premises.street_number = '1';
		premises.street_name = component.long_name;
	} else if (component.types.includes('route')) premises.street_name = component.long_name;
	else if (component.types.includes('sublocality')) premises.suburb = component.long_name;
	else if (component.types.includes('locality')) premises.city = component.long_name;
	else if (component.types.includes('administrative_area_level_1')) premises.province = component.long_name;
	else if (component.types.includes('country')) premises.country_code = component.short_name;
	else if (component.types.includes('postal_code')) premises.postal_code = component.short_name;

	return premises;
}

export function premiseFromPredefined(predefinedPremise: IClientPremise): Partial<IPremises> {
	const premises: Partial<IPremises> = {};
	premises.street_name = predefinedPremise.street_name;
	premises.street_number = predefinedPremise.street_number;
	premises.suburb = predefinedPremise.suburb;
	premises.city = predefinedPremise.city;
	premises.province = predefinedPremise.province;
	premises.country_code = predefinedPremise.country_code;
	premises.postal_code = predefinedPremise.postal_code;
	premises.mdu_name = predefinedPremise.mdu_name;
	premises.mdu_unit = predefinedPremise.mdu_unit;

	return premises;
}
